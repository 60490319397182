/Serv
<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <!-- Add the logo here -->
      <a class="navbar-brand" href="#" @click="setCurrentPage('home')">
        <img src="@/assets/imgs/logo.svg" alt="Logo" class="navbar-logo" />
      </a>

      <!-- Add the Vue-managed hamburger button for smaller screens -->
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleNavbar"
        aria-controls="navbarContent"
        aria-expanded="navbarExpanded"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navbar links for GenAI Research, GenAI Services, and Disclaimer -->
      <div
        :class="['collapse', 'navbar-collapse', { show: navbarExpanded }]"
        id="navbarContent"
      >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="#" @click="setCurrentPage('home')"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#leaderboard"
              @click="setCurrentPage('leaderboard')"
            >
              Leaderboard
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#research"
              @click="setCurrentPage('research')"
            >
              Our GenAI Research
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://consulting.withsecure.com/service/generative-ai-security/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Our GenAI Services
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click="openDisclaimerModal">
              Disclaimer
            </a>
          </li>
        </ul>
      </div>

      <!-- Username Dropdown -->
      <div v-if="user" class="d-flex align-items-center">
        <div class="dropdown" :class="{ show: isDropdownOpen }">
          <a
            class="btn btn-outline-light dropdown-toggle"
            href="#"
            role="button"
            @click.prevent="toggleDropdown"
          >
            {{ user.username }}
          </a>
          <div class="dropdown-menu" :class="{ show: isDropdownOpen }">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="openUsernameModal"
            >
              <i class="fas fa-user-edit"></i> Change Username
            </a>
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="openUserDeleteModal"
            >
              <i class="fas fa-user-times"></i> Request Account Deletion
            </a>
            <a class="dropdown-item" href="#" @click.prevent="logout">
              <i class="fas fa-sign-out-alt"></i> Logout
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation User Modal -->
    <div
      class="modal fade"
      :class="{
        'show d-block': isDeleteUserModalActive,
      }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Deletion Request</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeUserDeleteModal"
            ></button>
          </div>
          <div class="modal-body">
            <p v-if="!userDeleteErrorMessage">
              Are you sure you want to request the deletion of your account?
            </p>
            <div v-if="!userDeleteErrorMessage" class="alert alert-danger">
              This action cannot be reversed, and all information about your
              account will be deleted in 24 hours from now.
            </div>

            <p v-if="userDeleteErrorMessage" class="text-danger">
              {{ userDeleteErrorMessage }}
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeUserDeleteModal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteUser"
              :disabled="userDeleteErrorMessage !== ''"
            >
              Request Deletion
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      :class="{ 'show d-block': isUsernameModalActive }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Change Username</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeUsernameModal"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitNewUsername">
              <div class="mb-3">
                <label for="newUsername" class="form-label">New Username</label>
                <input
                  type="text"
                  class="form-control"
                  id="newUsername"
                  v-model="newUsername"
                  required
                />
              </div>
            </form>
            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeUsernameModal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="submitNewUsername"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <!-- Username Change Modal -->
</template>

<script>
export default {
  name: "AppNavbar",
  props: ["user"],
  data() {
    return {
      isDisclaimerModalActive: false,
      navbarExpanded: false,
      isDropdownOpen: false,
      isUsernameModalActive: false,
      isDeleteUserModalActive: false,
      newUsername: "",
      errorMessage: "",
      userDeleteErrorMessage: "",
    };
  },
  methods: {
    toggleNavbar() {
      this.navbarExpanded = !this.navbarExpanded;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    openUsernameModal() {
      this.isUsernameModalActive = true;
      this.closeDropdown(); // Close dropdown when opening the modal
    },
    closeUsernameModal() {
      this.isUsernameModalActive = false;
      this.errorMessage = "";
    },
    openUserDeleteModal() {
      if (this.user.soft_delete_request_timestamp) {
        const deletionRequestTime = new Date(
          this.user.soft_delete_request_timestamp
        ).toLocaleString();
        this.userDeleteErrorMessage = `A request to delete your account was already received at ${deletionRequestTime} and it will be enforced within 24 hours. You cannot request it again.`;
      }
      this.isDeleteUserModalActive = true;
      this.closeDropdown();
    },
    closeUserDeleteModal() {
      this.isDeleteUserModalActive = false;
    },
    async deleteUser() {
      try {
        // Make the API request to delete the user
        const response = await fetch("/api/user/delete", {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
          const errorData = await response.json();

          // Check if the error contains a timestamp for the deletion request
          const errorMessage = errorData.detail || "Error deleting account";

          // Use a regular expression to extract the timestamp from the error message
          const timestampMatch = errorMessage.match(
            /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d+/
          );
          if (timestampMatch) {
            // Convert the timestamp string to a JavaScript Date object
            const deletionTimestamp = new Date(timestampMatch[0]);

            // Format the timestamp for the user's locale using toLocaleString()
            const localizedTimestamp = deletionTimestamp.toLocaleString();

            // Show the error message with the localized timestamp
            this.userDeleteErrorMessage = `Failed to delete user: User has already requested deletion at ${localizedTimestamp}`;
          } else {
            // If no timestamp is found, just show the error message
            throw new Error(errorMessage);
          }
        } else {
          // If the deletion is successful, emit a logout event to the parent component
          this.$emit("refresh-user");
          this.isDeleteUserModalActive = false;
        }
      } catch (error) {
        this.userDeleteErrorMessage = `Failed to delete user: ${error.message}`;
      }
    },
    async submitNewUsername() {
      try {
        const response = await fetch("/api/user/change-username", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ new_username: this.newUsername }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Error changing username");
        }

        // If successful, update the username on the user object
        this.$emit("update-username", this.newUsername);
        this.closeUsernameModal();
      } catch (error) {
        this.errorMessage = `Failed to change username: ${error.message}`;
      }
    },
    async logout() {
      await fetch("/logout");
      this.$emit("logout");
    },
    openDisclaimerModal() {
      this.$emit("open-disclaimer-modal");
    },
    setCurrentPage(page_name) {
      this.$emit("set-current-page", page_name);
      this.navbarExpanded = false;
    },
    handleClickOutside(event) {
      // If the click is outside the dropdown, close the dropdown
      if (!this.$el.contains(event.target)) {
        this.closeDropdown();
      }
    },
  },
  mounted() {
    // Add event listener to close the dropdown when clicked outside
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    // Remove event listener when component is destroyed
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.navbar {
  background-color: #343a40;
  color: white;
}

.navbar-logo {
  height: 28px;
  width: auto;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-toggle::after {
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.modal.fade.show.d-block {
  display: block;
}

.navbar {
  z-index: 999;
}

/* Ensure dropdown does not overflow the viewport */
.dropdown-menu {
  right: 0;
  left: auto; /* Aligns the dropdown with the right side of its container */
}

@media (max-width: 576px) {
  .dropdown-menu {
    position: static; /* Prevents overflow on small screens */
    width: 100%; /* Makes dropdown fill the width of the screen */
  }
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: white;
  background-color: #5dc99f;
}

.modal-title {
  color: black;
}

.modal-body {
  color: black;
}
</style>
